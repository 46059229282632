import React, { useCallback, useState } from 'react';
import { graphql } from 'gatsby';
import axios from 'axios';
import { useLocation } from '@reach/router';
// import { captureException } from '@sentry/gatsby';
import Gate from './gate';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import LubeMain from './lube-main';
import GeneralHead from '../../components/generalHead';

const Landing = ({ data }) => {
  const hubspotFormId = 'f2f7cdb4-3814-4384-aae7-3dd293cc390c';
  const [isValidUser, setIsValidUser] = useState(false);
  const { href } = useLocation();

  const handleSubmit = useCallback(
    email => {
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/5424293/${hubspotFormId}`;

      const postData = {
        submittedAt: Date.now(),
        fields: [
          {
            name: 'email',
            value: email,
          },
        ],
        context: {
          pageUri: href,
          pageName: data.lube?.metaTags?.title,
        },
      };

      axios
        .post(url, postData)
        .then(() => setIsValidUser(true))
        .catch(err => {
          console.log(err);
          console.log(`Form Error - ${err}`);
        });
    },
    [hubspotFormId]
  );

  return (
    <Layout forceShowFooter>
      {!isValidUser ? <Gate onSubmit={handleSubmit} /> : <LubeMain />}
    </Layout>
  );
};

export const Head = ({
  data: {
    lube: { seo, headHtml, indexable, schemaMarkup },
  },
}) => (
  <GeneralHead>
    <SEO
      seo={seo}
      headHtml={headHtml}
      indexable={indexable}
      schemaMarkup={schemaMarkup}
    />
  </GeneralHead>
);

export const query = graphql`
  query LubeQuery {
    lube: datoCmsLube {
      indexable
      headHtml
      metaTags {
        title
      }
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      schemaMarkup {
        id
        schema
      }
    }
  }
`;

export default Landing;

import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { GateStyles } from './styles';
import OptimizedImage from '../../utils/OptimizedImage';
import smLightLogo from '../../images/sm-light-logo.svg';
import FormInput from '../shared/FormControl/Input';

const Gate = ({ onSubmit }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmail = useCallback(
    ({ target: { value } }) => setEmail(value),
    []
  );

  const handlePassword = useCallback(
    ({ target: { value } }) => setPassword(value),
    []
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      const isValidEmail = /^\S+@\S+\.\S+$/.test(email);

      // eslint-disable-next-line no-alert
      if (!isValidEmail) alert('Invalid email address!');
      if (isValidEmail && password === 'SM2022') {
        onSubmit(email, password);
      } else {
        // eslint-disable-next-line no-alert
        alert('Invalid Password!');
      }
    },
    [email, onSubmit, password]
  );

  return (
    <GateStyles>
      <div className="container lube-container">
        <div className="header">
          <OptimizedImage src={smLightLogo} alt="Shopmonkey Lube Page" />
          <p className="lube-text">
            Please log in with the password you’ve been provided to view a demo
            of Shopmonkey’s Quick Lube Order Builder prototype
          </p>
        </div>
        <form>
          <div className="gate-form">
            <FormInput
              required
              id="email"
              name="email"
              type="email"
              className="w-100 mb-0"
              placeholder="Email address"
              label="Email"
              value={email}
              onChange={handleEmail}
            />

            <FormInput
              required
              id="password"
              name="password"
              type="password"
              className="w-100 mb-0"
              placeholder="Input password"
              label="Password"
              value={password}
              onChange={handlePassword}
            />

            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </GateStyles>
  );
};

export default Gate;
